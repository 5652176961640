import React, { useState, useEffect } from 'react'
import NavbatLogo from '../assets/logoNavbat.svg'
import questionIcon from '../assets/question.svg'
import { Link, useLocation } from 'react-router-dom'
import SearchQueue from '../components/SearchQueue'
import { Modal, Spinner } from 'react-bootstrap'
import {
	Button,
	ModalBody,
	ModalFooter,
	ModalTitle
} from 'react-bootstrap'
import moment from 'moment'
import { toast } from 'react-toastify'
import Tip from '../components/Tip'
import './css/Home.scss'
import './css/QueueAll.css'
// import { useTelegram } from '../components/telegram'
// import Cookies from 'universal-cookie'
import { queueCategoryStore } from '../store/queueCategoryStore'
import { authStore } from '../store/authStore'
import { START_ROUTE } from '../util/routenames'
import { RegisterModal } from '../components/modals/RegisterModal'
import { useTranslation } from 'react-i18next'
import { recepientStore } from '../store/recepientStore'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'
import { toDataURL } from 'qrcode'
import { Helmet } from 'react-helmet'

export const Home = () => {
	const location = useLocation()
	const { t } = useTranslation()
	// const { tgUser } = useTelegram()
	// const cookie = useMemo(() => new Cookies(), [])

	const { user, isNewAcc, isNewAccFetched } = authStore()
	const { queueCategory } = queueCategoryStore()
	const { recepients, finishRecepient } = recepientStore()

	const [loading, setLoading] = useState(true)
	const [showtip, setShowtip] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isModalOpenShare, setIsModalOpenShare] = useState(false)
	const [qrImg, setQrImg] = useState()

	const [modalQueueScheme, setModalQueueScheme] = useState()

	const reg = location.search.includes('?reg')

	useEffect(() => {
		if (
			!moment(user?.lastSeen).isAfter(
				moment().subtract(90, 'days')
			) ||
			reg
		) {
			setShowtip(true)
			setLoading(false)
		} else {
			setShowtip(false)
			setLoading(false)
		}
	}, [reg, location.search, user])

	// useEffect(() => {
	// 	ClientGetHomeAll()
	// 		.then((data) => setClientQueues(data))
	// 		.finally(() => setLoading(false))
	// }, [])

	const exitFunction = async (queueScheme, e) => {
		e.preventDefault()
		try {
			await finishRecepient(
				queueScheme.id,
				queueScheme.queues[0].id,
				queueScheme.queues[0].recepients[0].id
			)
			setIsModalOpen(false)
			toast.error(t('YouExitedQueue'))
		} catch (e) {
			console.log(e)
			toast.error(e.response.data.message)
		}
	}

	const openExitModal = (e, qs) => {
		e.preventDefault()
		setModalQueueScheme(qs)
		setIsModalOpen(true)
	}

	const handleCloseShare = () => setIsModalOpenShare(false)

	const handleClose = () => setIsModalOpen(false)

	const shareModul = (e, queue) => {
		e.preventDefault()
		setIsModalOpenShare(true)
		if (!queue) return
		const url =
			process.env.REACT_APP_SITE_URL + 'r/' + queue.unique
		toDataURL(url, {
			width: 150,
			margin: 0,
			color: { light: '#00000000' }
		})
			.then((data) => {
				const img = React.createElement('img', {
					src: data,
					alt:
						process.env.REACT_APP_SITE_URL +
						'r/' +
						queue.unique +
						'?ref=' +
						user.nvbtId
				})
				setQrImg(img)
			})
			.catch((e) => console.error(e))
	}

	if (loading) {
		return (
			<center>
				<h2>
					L<Spinner animation={'border'} />
					ADING
				</h2>
			</center>
		)
	}

	document.body.removeAttribute('style')
	return (
		<>
			<Helmet>
				<title>{t('YourQueues')} – Queue Expert</title>
				<meta
					name="description"
					content={t('YourQueuesDesc')}
				/>
			</Helmet>
			{showtip ? (
				<Tip onAction={() => setShowtip(false)} />
			) : null}
			<header className="home">
				<section className="logoCont">
					<img src={NavbatLogo} alt="Logo Navbat" />
				</section>
				<SearchQueue />

				<section className="textCont">
					<p className="m-0">{t('YourQueues')}</p>
				</section>
			</header>
			{isNewAcc && isNewAccFetched && <RegisterModal />}
			{queueCategory.map((category, i) => (
				<div key={i}>{category.name}</div>
			))}
			{/* <button onClick={() => cookie.remove('access')}>
				{' '}
				Access
			</button>
			<button onClick={() => cookie.remove('refresh')}>
				{' '}
				Refresh
			</button> */}
			<main className="home">
				<Modal
					className="custom-modal-home"
					show={isModalOpen}
					centered
					onHide={handleClose}>
					<ModalHeader>
						<ModalTitle>
							{t('DoYouReallyWantToExit')} ?
						</ModalTitle>
					</ModalHeader>
					<ModalFooter>
						<div
							style={{
								width: '100%',
								gap: '10px',
								display: 'flex'
							}}>
							<Button
								submit=""
								onClick={(e) => {
									e.preventDefault()
									setIsModalOpen(false)
								}}>
								{t('IStay')}
							</Button>
							<Button
								submit=""
								onClick={(e) =>
									exitFunction(modalQueueScheme, e)
								}>
								{t('Yes')}
							</Button>
						</div>
					</ModalFooter>
				</Modal>
				<Modal
					show={isModalOpenShare}
					centered
					onHide={handleCloseShare}>
					<ModalHeader>
						<ModalTitle>
							{t('ThisIsYourQueuesQRCode')}
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<div className="cabinRow mx-auto ">
							{qrImg}
						</div>
					</ModalBody>
					<ModalFooter>
						<div className="buttonClose">
							<Button
								onClick={() =>
									setIsModalOpenShare(false)
								}>
								{t('Close')}
							</Button>
						</div>
					</ModalFooter>
				</Modal>

				{recepients.length ? (
					recepients.map((queueScheme) => (
						<Link
							key={queueScheme.id}
							to={'/observe/' + queueScheme.id}>
							<section
								className={
									queueScheme.queues[0]
										.recepients[0].status ===
									'calling'
										? 'recordsIndex recordsBorderGreen'
										: 'recordsIndex'
								}>
								<div className="homeCardMain">
									<h5 className="queueName">
										{queueScheme.name}
									</h5>
									<div className="homeCardQueueName">
										{queueScheme.queues[0]
											.recepients[0].status ===
											'calling' ||
										queueScheme.queues[0]
											.recepients[0].status ===
											'active' ? (
											<div className="queueNumCalling">
												<b>Your turn</b>
											</div>
										) : (
											<div className="queueNum">
												<b>
													{t('Your')} №{' '}
													{
														queueScheme
															.queues[0]
															.recepients[0]
															.number
													}
												</b>
											</div>
										)}

										<p className="queueTime">
											{t('AverageInterval')}:{' '}
											{/* Fix this bitch */}
											{queueScheme.inner
												?.activeWorkTime
												? (
														queueScheme.inner?.activeWorkTime.reduce(
															(
																a,
																b,
																_ar,
																arr
															) =>
																(a +
																	b) /
																arr.length
														) / 1000
													).toFixed(2) +
													' мин.(сек)'
												: t('NoData')}
										</p>
										{queueScheme.room ? (
											<b
												className="queueNum"
												style={{
													marginLeft: '5px'
												}}>
												{t('Room')}{' '}
												{queueScheme.room}
											</b>
										) : (
											<></>
										)}
									</div>
								</div>
								<div className="d-flex">
									<i className="la la-calendar-check la-2x cGray"></i>
									<div className="pl-3 flex-grow-1">
										<p className="queueCrTimeText">
											{t('InLineWith')}
										</p>
										<b className="queueCrTime">
											{moment(
												queueScheme.queues[0]
													.recepients[0]
													.createdAt
											).format(
												'DD.MM.YYYY HH:mm'
											)}
										</b>
									</div>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											alignItems: 'center',
											gap: '5px'
										}}>
										<p
											className="queueCrTimeText"
											style={{
												marginRight: '5px'
											}}>
											{t('YourStatus')}
										</p>
										<b
											style={{
												fontSize: '1em',
												color: '#000'
											}}>
											{t(
												queueScheme.queues[0]
													.recepients[0]
													.status
											)}
										</b>
									</div>
									<div className="queueCurrentTime">
										<p className="queueCrTimeText">
											{t('Current')} №
										</p>
										<b className="queueCrTime">
											{!queueScheme.queues[0]
												.active
												? t('Free')
												: queueScheme
															.queues[0]
															.active ===
													  queueScheme
															.queues[0]
															.recepients[0]
															.number
													? t('Yours')
													: queueScheme
															.queues[0]
															.active}
										</b>
									</div>
								</div>
								<div className="d-flex">
									<i className="la la-info la-2x cGray"></i>
									<div className="pl-3 flex-grow-1">
										<p className="queueCrTimeText">
											{t('Note')}
										</p>
										<b className="queueCrTime">
											{queueScheme.description}
										</b>
									</div>
									<div className="queueCurrentTime">
										<p className="queueCrTimeText">
											{t('Queue')} №
										</p>
										<b className="queueCrTime">
											{queueScheme.unique}
										</b>
									</div>
								</div>
								<nav className="d-flex">
									<button
										className="queueBtn error mr-3"
										onClick={(e) =>
											openExitModal(
												e,
												queueScheme
											)
										}>
										<i className="la la-door-open la-2x"></i>
										{t('Exit')}
									</button>
									<button
										className="queueBtn bgGray"
										onClick={(e) =>
											shareModul(e, queueScheme)
										}>
										<i className="la la-share la-2x"></i>
										{t('Share')}
									</button>
								</nav>
							</section>
						</Link>
					))
				) : (
					<article className="emptyHome">
						<h3 className="emptyHomeHeader">
							{t('Dear')}{' '}
							<span className="text-capitalize">
								{user?.firstName + '! '}
								<Link to={START_ROUTE}></Link>
							</span>
							<br />
							{t('YouHaveNotQueue')}
						</h3>
						<p className="emptyHomeText">
							{t('ProvideCodeOrQR')}
						</p>
					</article>
				)}
			</main>
			<nav>
				<Link className="infoLogo" to="/?reg">
					<img src={questionIcon} alt="?" />
				</Link>
			</nav>
		</>
	)
}
