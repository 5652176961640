import { $authHost } from '.'

export const addToWishlist = async (queueSchemeId) => {
	const { data } = await $authHost.post('wishlist', {
		queueSchemeId
	})
	return data
}

export const getWishlist = async (page) => {
	const { data } = await $authHost.get('wishlist?page=' + page)
	return data
}

export const removeFromWishlist = async (queueSchemeId) => {
	const { data } = await $authHost.delete(
		'wishlist/' + queueSchemeId
	)
	return data
}
