import { create } from 'zustand'
import {
	addToWishlist,
	getWishlist,
	removeFromWishlist
} from '../http/wishlistApi'

export const wishlistStore = create((set) => ({
	wishlist: [],

	addToWishlist: async (queueSchemeId, name, unique) => {
		if (
			wishlistStore
				.getState()
				.wishlist.some(
					(w) => w.queueSchemeId === queueSchemeId
				)
		) {
			await removeFromWishlist(queueSchemeId)
			set((state) => {
				return {
					...state,
					wishlist: state.wishlist.filter(
						(w) => w.queueSchemeId !== queueSchemeId
					)
				}
			})
		} else {
			const data = await addToWishlist(queueSchemeId)
			set((state) => {
				const newData = { ...data, name, unique }
				return {
					...state,
					wishlist: [newData, ...state.wishlist]
				}
			})
		}
	},

	isInWishlist: (queueSchemeId) => {
		return wishlistStore
			.getState()
			.wishlist.some((w) => w.queueSchemeId === queueSchemeId)
	},

	getWishlist: async (page) => {
		const wishlist = await getWishlist(page)
		set((state) => ({
			wishlist: [...wishlist, ...state.wishlist]
		}))
	}
}))
