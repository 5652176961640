import moment from 'moment'
import React, {
	useCallback,
	useEffect,
	useRef,
	useState
} from 'react'
import {
	FrownOutlined,
	MehOutlined,
	SmileOutlined
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { recepientStore } from '../store/recepientStore'
import { Flex, Modal, Rate } from 'antd'

const HistoryEmployed = ({ ...props }) => {
	const { t } = useTranslation()

	const { getRecepientHistory, sendReview, recepientHistory } =
		recepientStore()

	const [page, setPage] = useState(1)
	const [hasMore, setHasMore] = useState(true)
	const [open, setOpen] = useState(false)
	const [rate, setRate] = useState({ rate: 0, queue: {} })
	const [comment, setComment] = useState('')

	const observer = useRef(null)

	const lastElementRef = useRef(null)

	const rateValue = (number, queue) => {
		setOpen(true)
		setRate({ rate: number, queue })
	}

	const cancelFC = () => {
		setOpen(false)
		setComment('')
	}

	const submitFc = ({
		queueSchemeId,
		queueId,
		id: recepientId,
		providerId,
		serviceId
	}) => {
		setOpen(false)
		setComment('')
		sendReview({
			queueSchemeId,
			queueId,
			recepientId,
			providerId,
			userId: null,
			serviceId,
			rating: rate.rate,
			comment
		})
	}

	const fetchMoreData = useCallback(async () => {
		const recepientHistory = await getRecepientHistory(page)
		if (recepientHistory.length < 10) {
			setHasMore(false)
		}
	}, [getRecepientHistory, page])

	useEffect(() => {
		fetchMoreData()
	}, [page, fetchMoreData])

	useEffect(() => {
		if (!recepientHistory.length || recepientHistory.length < 10)
			return
		if (observer.current) observer.current.disconnect()

		observer.current = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting && hasMore) {
					setPage((prevPage) => prevPage + 1)
				}
			},
			{
				root: null,
				rootMargin: '100px',
				threshold: 0.5
			}
		)

		if (lastElementRef.current) {
			observer.current.observe(lastElementRef.current)
		}
	}, [hasMore, recepientHistory])

	const customIcons = {
		1: <FrownOutlined />,
		2: <FrownOutlined />,
		3: <MehOutlined />,
		4: <SmileOutlined />,
		5: <SmileOutlined />
	}

	const classIcon = (queue) => {
		if (queue.status === 'active') {
			return ['iconGreen', 'arrow-circle-right', t('Active')]
		} else if (queue.status === 'calling') {
			return ['iconGreen', 'running', t('Calling')]
		} else if (queue.status === 'pending') {
			return ['iconYellow', 'stopwatch', t('Pending')]
		} else if (queue.status === 'done') {
			return ['iconGray', 'check-circle', t('Done')]
		} else if (queue.status === 'missed') {
			return ['iconOrange', 'arrow-circle-left', t('Missed')]
		} else if (queue.status === 'cancelled') {
			return ['iconRed', 'times-circle', t('Cancelled')]
		} else if (queue.status === 'skipping') {
			return ['iconOrange', 'pause-circle', t('Skipping')]
		} else {
			return ['iconRed', 'exclamation-circle', t('Unknown')]
		}
	}
	return (
		<>
			<Modal
				title="Rating"
				open={open}
				onCancel={() => setOpen(false)}
				centered
				footer={
					<div
						style={{
							display: 'flex',
							gap: '10px'
						}}>
						<button submit="" onClick={cancelFC}>
							Cancel
						</button>
						<button
							submit=""
							onClick={() => submitFc(rate.queue)}>
							Ok
						</button>
					</div>
				}>
				<div className="doneModalBody">
					<div className="doneModalText">
						<p>Your queue has been done !</p>
						<p>Please rate our service </p>
					</div>

					<Flex gap="middle" vertical>
						<Rate
							value={rate.rate}
							onChange={(rate) =>
								setRate((prev) => ({ ...prev, rate }))
							}
							className="reviewRate"
							defaultValue={0}
							character={({ index = 0 }) =>
								customIcons[index + 1]
							}
						/>
					</Flex>

					<textarea
						value={comment}
						onChange={(e) => setComment(e.target.value)}
						placeholder="Leave Your Comments...."
					/>
				</div>
			</Modal>

			{recepientHistory.length ? (
				<section {...props}>
					{recepientHistory.map((queue, index) => (
						<div
							className="hisGot"
							key={queue.id}
							ref={
								recepientHistory.length === index + 1
									? lastElementRef
									: null
							}>
							<div
								className={
									'queueIconRating ' +
									classIcon(queue)[0]
								}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center'
									}}>
									<i
										className={
											'la la-' +
											classIcon(queue)[1] +
											' la-2x'
										}></i>
									<div className="ml-2">
										{classIcon(queue)[2]}
									</div>
								</div>
								<div className="queueRating">
									{queue.status === 'done' ? (
										<Rate
											disabled={!!queue.rate}
											onChange={(v) =>
												rateValue(v, queue)
											}
											value={queue.rate}
										/>
									) : (
										<></>
									)}
								</div>
							</div>
							<div className="hisQueue">
								<div className="flex-grow-1">
									<div className="queueNameRating">
										<h5 className="queueName">
											{queue.name}
										</h5>
										{+queue.rating === 0 ? (
											<span>
												{t('NotRatedYet')}
											</span>
										) : (
											<>
												<span>
													<i
														className="las la-star"
														style={{
															color: 'gold',
															marginRight:
																'3px'
														}}></i>
													{Math.floor(
														queue.rating *
															10
													) / 10}
												</span>
												<span>
													(
													{queue.ratesCount}{' '}
													{t('Reviews')})
												</span>
											</>
										)}
									</div>
									<p className="queueTime">
										{t('AverageInterval')}: 0{' '}
										{t('Minute')}
									</p>
								</div>
								{[
									'pending',
									'calling',
									'active'
								].includes(queue.status) ? (
									<div className="queueNum">
										<p>{t('Your')} №</p>
										<p>{queue.number}</p>
									</div>
								) : null}
							</div>
							<div className="hisQueue">
								<i className="la la-calendar-check la-2x cGray"></i>
								<div className="ml-2">
									<p className="queueCrTimeText">
										{t('InLineWith')}
									</p>
									<p className="queueCrTime">
										{moment(
											queue.createdAt
										).format('DD.MM.YYYY HH:mm')}
									</p>
								</div>
							</div>
						</div>
					))}
				</section>
			) : (
				<article className="emptyHome">
					<h3 className="emptyHomeHeader">
						{t('NoQueuesCreatedYet')}
					</h3>
					<p className="emptyHomeText">
						{t('AddNewQueueUseButton')}
					</p>
				</article>
			)}
		</>
	)
}

export default HistoryEmployed
