import { create } from 'zustand'
import {
	activate,
	checkQueueScheme,
	createQueueScheme,
	getOneQueueSchemeFromClient,
	getQueueSchemes
} from '../http/queueSchemeApi'
import { getReviewByQueueSchemeId } from '../http/recepientApi'

export const queueSchemeStore = create((set) => ({
	queueScheme: [],
	reviews: [],
	// joiningQueueSchemes: [],
	createQueueScheme: async (queueScheme) => {
		const data = await createQueueScheme(queueScheme)
		set((state) => {
			return {
				...state,
				queueScheme: [...state.queueScheme, data]
			}
		})
		return { id: data.id }
	},
	getQueueSchemes: async () => {
		const queueScheme = await getQueueSchemes()

		set(() => ({ queueScheme }))
	},
	activate: async (queueSchemeId, startDate) => {
		await activate(queueSchemeId, startDate)

		queueSchemeStore
			.getState()
			.queueScheme.find(
				(qs) => qs.id === queueSchemeId
			).isJoinable = true

		// TODO: FINISH THERE!!!
	},
	checkQueueScheme: async (unique) => {
		const data = await checkQueueScheme(unique)
		return data
	},
	getOneQueueSchemeFromClient: async (queueSchemeId) => {
		const cachedQS = queueSchemeStore
			.getState()
			.queueScheme.find((qs) => qs.id === queueSchemeId) //.joiningQueueSchemes
		if (cachedQS) return cachedQS

		const queueScheme =
			await getOneQueueSchemeFromClient(queueSchemeId)

		set((state) => {
			return {
				...state,
				// joiningQueueSchemes: [
				// 	...state.joiningQueueSchemes,
				// 	queueScheme
				// ],
				queueScheme: [...state.queueScheme, queueScheme]
			}
		})

		return queueScheme
	},
	skipRecepient: (queueSchemeId, recepientId, number, status) => {
		set((state) => {
			return {
				...state,
				queueScheme: state.queueScheme.map((qs) => {
					if (queueSchemeId === qs.id) {
						return {
							...qs,
							queues: qs.queues.flatMap((q) => ({
								...q,
								recepients: q.recepients.map((rr) => {
									if (rr.id === recepientId) {
										return {
											...rr,
											status,
											...(number
												? { number }
												: {})
										}
									}
									return rr
								})
							}))
						}
					}
					return qs
				})
			}
		})
	},
	returnRecepient: (queueSchemeId, recepientId) => {
		set((state) => {
			return {
				...state,
				queueScheme: state.queueScheme.map((qs) => {
					if (queueSchemeId === qs.id) {
						return {
							...qs,
							queues: qs.queues.flatMap((q) => ({
								...q,
								recepients: q.recepients.map((rr) => {
									if (rr.id === recepientId) {
										return {
											...rr,
											status: 'pending'
										}
									}
									return rr
								})
							}))
						}
					}
					return qs
				})
			}
		})
	},
	setQueueClientJoinFc: async ({ queueSchemeId }) => {
		set((state) => {
			const newData = state.queueScheme.map((qs) => {
				if (qs.id === queueSchemeId) {
					return {
						...qs,
						recepientsCount:
							+qs.queues[0].recepientsCount + 1
					}
				}
				return qs
			})
			return {
				...state,
				queueScheme: newData
			}
		})
	},

	getReviewByQueueSchemeId: async (queueSchemeId, page) => {
		const state = queueSchemeStore.getState()
		const reviewLength = state.reviews.filter(
			(r) => r.queueSchemeId === queueSchemeId
		).length
		const itemsPerPage = 10

		if (reviewLength >= page * itemsPerPage) {
			return state.reviews.slice(
				(page - 1) * itemsPerPage,
				page * itemsPerPage
			)
		}

		if (
			(reviewLength &&
				reviewLength / itemsPerPage < page - 1) ||
			(reviewLength && reviewLength / itemsPerPage < 1)
		) {
			return []
		}

		const reviews = await getReviewByQueueSchemeId(
			queueSchemeId,
			page
		)

		set((state) => ({
			...state,
			reviews: [...state.reviews, ...reviews]
		}))

		return reviews
	}
}))
