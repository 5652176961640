import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './css/QueueAll.css'
import moment from 'moment'
import { Spinner } from 'react-bootstrap'
import {
	CREATE_QUEUE_SCHEME_ROUTE,
	QUEUE_SCHEME_ROUTE
} from '../util/routenames'
import { queueSchemeStore } from '../store/queueSchemeStore'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
export const QueueSchemes = () => {
	const [loading] = useState(false)

	const { queueScheme: queues } = queueSchemeStore()
	const { t } = useTranslation()

	if (loading) {
		return (
			<center>
				<h2>
					L<Spinner animation={'border'} />
					ADING
				</h2>
			</center>
		)
	}
	return (
		<>
			<Helmet>
				<title>{t('Queues')} – Queue Expert</title>
				<meta name="description" content={t('QueuesDesc')} />
			</Helmet>
			<header>
				<h2 className="mainTopTitle">
					{!queues.length ? t('Queues') : t('ListOfQueues')}
				</h2>
			</header>
			<main className="QAMain">
				{queues.length ? (
					queues.map((queue, i) => (
						<Link
							to={QUEUE_SCHEME_ROUTE + '/' + queue.id}
							className="QA"
							key={queue.id || i}>
							<h5 className="mt-3">{queue?.name}</h5>
							<div className="QAProp">
								<i className="la la-calendar-plus la-2x"></i>
								<div className="flex-grow-1">
									<div>{t('CreatedData')}</div>
									<div className="QADate">
										{moment(
											queue.createdAt
										).format('DD.MM.yyyy')}
									</div>
								</div>
								<figcaption className="d-flex flex-column align-items-center">
									<div>{t('CurrentNumber')}</div>
									<div className="QADate">
										{queue.activeClient}
									</div>
								</figcaption>
								<div className="QADate">
									<i className="la la-2x la-angle-right"></i>
								</div>
							</div>
						</Link>
					))
				) : (
					<article className="emptyHome">
						<h3 className="emptyHomeHeader">
							{t('NoQueuesCreatedYet')}
						</h3>
						<p className="emptyHomeText">
							{t('AddNewQueueUseButton')}
						</p>
					</article>
				)}
				<div className="QANew btnSubmit pl-3 pr-3">
					<Link
						to={CREATE_QUEUE_SCHEME_ROUTE}
						className="QANewBtn"
						submit=""
						// onClick={() =>
						// 	history.push(QUEUE_CREATE_ROUTE)
					>
						{t('Create')}
					</Link>
				</div>
			</main>
		</>
	)
}
