import React from 'react'
import { wishlistStore } from '../store/wishlistStore'
import './css/wishlist.scss'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export const Wishlist = () => {
	const { t } = useTranslation()

	const { wishlist } = wishlistStore()
	return (
		<>
			<Helmet>
				<title>{t('Favorites')} – Queue Expert</title>
				<meta name="description" content={t('ObserveDesc')} />
			</Helmet>
			<header className="QCHeader">
				<h2 className="mainTopTitle">{t('Favorites')}</h2>
			</header>
			{wishlist.length ? (
				<main className="">
					{wishlist.map((w) => (
						<Link
							to={'/observe/' + w.queueSchemeId}
							className="wishlist">
							<div className="wishlistMain" key={w.id}>
								<h2 className="wishlistName">
									{w.name}
								</h2>
								<div className="wishlistCode">
									<p>{t('Queue')} №</p>
									<b>{w.unique}</b>
								</div>
							</div>
						</Link>
					))}
				</main>
			) : (
				<main className="WishlistMain">
					<div className="NoWishlistContainer">
						<h2 className="NoWishlist">
							{t('YouHaveNotFavoriteListYet')}
						</h2>
					</div>
				</main>
			)}
		</>
	)
}
