import React from 'react'
import { Navbar, Container } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import {
	HISTORY_ROUTE,
	HOME_ROUTE,
	PROFILE_ROUTE,
	WISHLIST_ROUTE
} from '../util/routenames'
import { useTranslation } from 'react-i18next'

const NavBar = () => {
	const just = useLocation().pathname.split('/')[1]
	const { t } = useTranslation()

	if (just === '404') return null

	return (
		<Navbar className="navFooter" as={'footer'}>
			<Container>
				<Link
					className={
						'btnFooterNav' +
						(just === '' ? ' active' : '')
					}
					to={HOME_ROUTE}>
					<i className="la la-home la-3x"></i>
					<br />
					{t('Home')}
				</Link>
				<Link
					className={
						'btnFooterNav' +
						(just === 'wishlist' ? ' active' : '')
					}
					to={WISHLIST_ROUTE}>
					<i class="las la-grin-hearts la-3x"></i>
					<br />
					{t('Queue')}
				</Link>
				<Link
					className={
						'btnFooterNav' +
						(just === 'monitoring' ? ' active' : '')
					}
					to={HISTORY_ROUTE}>
					<i className="la la-history la-3x"></i>
					<br />
					{t('Monitoring')}
				</Link>
				<Link
					className={
						'btnFooterNav' +
						(just === 'profile' ? ' active' : '')
					}
					to={PROFILE_ROUTE}>
					<i className="la la-user la-3x"></i>
					<br />
					{t('Profile')}
				</Link>
			</Container>
		</Navbar>
	)
}

export default NavBar
