import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import MaskInput from 'react-maskinput'
import { authStore } from '../store/authStore'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'

const schema = yup.object({
	loginphone: yup.string().required('Phone number is required ')
})

export const ChangeNumberModal = ({
	handleNumber,
	setHandleNumber
}) => {
	const { t } = useTranslation()

	const { user, changeUserNumber } = authStore()

	const [number, setNumber] = useState(user.loginphone)

	const {
		// handleSubmit,
		register,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(schema)
	})

	const onCancelNumberFc = () => {
		if (!user) return
		setHandleNumber(false)
		setNumber(user.loginphone)
	}

	const changeUserNumberSubmitFc = async () => {
		// await handleSubmit((data) => {
		changeUserNumber({ loginphone: number })
		setHandleNumber(false)
		// }, console.log)(e)
	}

	useEffect(() => {
		register('loginphone')
	}, [register])

	return (
		<Modal
			className="cabinetModal"
			open={handleNumber}
			onCancel={() => setHandleNumber(false)}
			centered
			footer={
				<div className="cabinetModalBt">
					<button submit="" onClick={onCancelNumberFc}>
						{t('Cancel')}
					</button>
					<button
						submit=""
						type="submit"
						onClick={changeUserNumberSubmitFc}>
						{t('Submit')}
					</button>
				</div>
			}>
			<div className="cabinetModals">
				<div className="cabinetTextInput">
					<p>{t('PhoneNumber')}</p>
					<MaskInput
						mask="+998(00)000-00-00"
						// {...register('loginphone')}
						onChange={(e) => setNumber(e.target.value)}
						value={number}
					/>
					<span className="cabinetNamesError">
						{errors.loginphone?.message}
					</span>
				</div>
			</div>
		</Modal>
	)
}
