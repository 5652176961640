import React, { useState } from 'react'
import { authStore } from '../store/authStore'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'

const schema = yup.object({
	firstName: yup
		.string()
		.required('First name is required field')
		.matches(
			/^[A-Za-zА-Яа-я' -]+$/,
			'First name should contain only letters'
		),
	middleName: yup
		.string()
		.required('Middle name is required field')
		.matches(
			/^[A-Za-zА-Яа-я' -]+$/,
			'Middle name should contain only letters'
		),
	lastName: yup
		.string()
		.required('Last name is required field')
		.matches(
			/^[A-Za-zА-Яа-я' -]+$/,
			'Last name should contain only letters'
		)
})

export const ChangeUserInfoModal = ({
	handleModal,
	setHandleModal
}) => {
	const { user, changeUserInfo, isAuthChangesDisabled } =
		authStore()

	const [names, setNames] = useState({
		firstName: user.firstName,
		middleName: user.middleName,
		lastName: user.lastName
	})

	const { t } = useTranslation()

	const {
		handleSubmit,
		register,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(schema)
	})

	const isChangingDisabled = isAuthChangesDisabled.find(
		(number) => number.type === 'namesChange'
	)

	const changeUserInfoFc = (e, name) => {
		setNames((state) => ({
			...state,
			[name]: e.target.value
		}))
	}

	const changeUserSubmitFc = async (e) => {
		if (
			user?.firstName === names.firstName &&
			user?.middleName === names.middleName &&
			user?.lastName === names.lastName
		)
			return setHandleModal(false)

		await handleSubmit((data) => {
			changeUserInfo(data)
			setHandleModal(false)
		})(e)
	}

	const onCancelFc = () => {
		setHandleModal(false)
		setNames({
			firstName: user.firstName,
			middleName: user.middleName,
			lastName: user.lastName
		})
	}
	return (
		<Modal
			className="cabinetModal"
			open={handleModal}
			onCancel={() => setHandleModal(false)}
			centered
			footer={
				<div className="cabinetModalBt">
					<button submit="" onClick={onCancelFc}>
						{t('Cancel')}
					</button>
					<button
						disabled={!!isChangingDisabled}
						submit=""
						type="submit"
						onClick={changeUserSubmitFc}>
						{t('Submit')}
					</button>
				</div>
			}>
			<div className="cabinetModals">
				<div className="cabinetTextInput">
					<p>{t('FirstName')}</p>
					<input
						{...register('firstName')}
						onChange={(e) =>
							changeUserInfoFc(e, 'firstName')
						}
						value={names.firstName}
					/>
					<span className="cabinetNamesError">
						{errors.firstName?.message}
					</span>
				</div>
				<div className="cabinetTextInput">
					<p>{t('MiddleName')}</p>
					<input
						{...register('middleName')}
						onChange={(e) =>
							changeUserInfoFc(e, 'middleName')
						}
						value={names.middleName}
					/>
					<span className="cabinetNamesError">
						{errors.middleName?.message}
					</span>
				</div>
				<div className="cabinetTextInput">
					<p>{t('LastName')}</p>
					<input
						{...register('lastName')}
						onChange={(e) =>
							changeUserInfoFc(e, 'lastName')
						}
						value={names.lastName}
					/>
					<span className="cabinetNamesError">
						{errors.lastName?.message}
					</span>
				</div>
			</div>
			<div className="cabinetChangeBlocked">
				{isChangingDisabled
					? t('NumberChangingBlockedOpensIn') +
						' ' +
						new Date(
							new Date(
								isChangingDisabled.nextAvailableTime
							)
						).toLocaleString()
					: ''}
			</div>
		</Modal>
	)
}
