import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import './css/QueueCreate.scss'
import { languageStore } from '../store/languageStore'
import { serviceStore } from '../store/serviceStore'
import { useNavigate, useParams } from 'react-router-dom'
import {
	QUEUE_SCHEME_ROUTE,
	SERVICE_LIST_ROUTE
} from '../util/routenames'
import { useTranslation } from 'react-i18next'
import { authStore } from '../store/authStore'
import { Helmet } from 'react-helmet'

export const CreateService = () => {
	const { language } = languageStore()
	const { user } = authStore()
	const { createService } = serviceStore()
	const { t } = useTranslation()
	const { id } = useParams()
	const navigate = useNavigate()

	const [queue, setQueue] = useState({})
	const [service, setService] = useState({})
	const [selectedLang, setSelectedLang] = useState(
		language.find((l) => l?.id === user.langId)
	)

	const createServiceFc = () => {
		createService({
			translation: queue,
			...service,
			queueSchemeId: id
		})
		navigate(QUEUE_SCHEME_ROUTE + '/' + id + SERVICE_LIST_ROUTE)
	}

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<Helmet>
				<title>{t('СreateService')} – Queue Expert</title>
				<meta
					name="description"
					content={t('СreateServiceDesc')}
				/>
			</Helmet>
			<header className="QCHeader">
				<i
					className="la la-angle-left la-2x"
					onClick={() => window.history.back()}></i>
				<h2 className="mainTopTitle flex-grow-1">
					{t('СreateService')}
				</h2>
			</header>
			<main className="QCMain">
				<label>
					<span>{t('QueueName')}</span>
					<div className="inputDiv">
						<input
							value={queue[selectedLang.id]?.name || ''}
							onChange={(e) => {
								setQueue((old) => ({
									...old,
									[selectedLang.id]: {
										...old[selectedLang.id],
										name: e.target.value
									}
								}))
							}}
						/>

						<Dropdown className="dropdown">
							<Dropdown.Toggle
								variant="error"
								id="dropdown-basic">
								<img
									src={selectedLang.flag}
									style={{
										height: '40px'
									}}
									alt="flag"
								/>
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{language.map((lang) => (
									<Dropdown.Item
										className="dropdownitem"
										onClick={() =>
											setSelectedLang(lang)
										}
										key={lang.id}>
										{lang.name}
										<img
											src={lang.flag}
											alt="flag"
										/>
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</label>

				<label>
					<span>{t('Description')}</span>
					<input
						className="textarea"
						value={
							queue[selectedLang.id]?.description || ''
						}
						onChange={(e) => {
							setQueue((old) => ({
								...old,
								[selectedLang.id]: {
									...old[selectedLang.id],
									description: e.target.value
								}
							}))
						}}
					/>
				</label>
				<label>
					<span>{t('Duration')}</span>
					<div
						style={{
							display: 'flex',
							alignItems: 'flex-end',
							gap: '20px'
						}}>
						<input
							type="number"
							className="textarea"
							value={service?.duration || ''}
							onChange={(e) => {
								setService((old) => ({
									...old,
									duration: +e.target.value
								}))
							}}
						/>
						{t('min')}
					</div>
				</label>
				<label>
					<span>{t('Fee')}</span>
					<div
						style={{
							display: 'flex',
							alignItems: 'flex-end',
							gap: '20px'
						}}>
						<input
							type="number"
							className="textarea"
							value={service?.fee || ''}
							onChange={(e) => {
								setService((old) => ({
									...old,
									fee: +e.target.value
								}))
							}}
						/>
						{t('sum')}
					</div>
				</label>
				<label
					style={{
						display: 'flex',
						alignItems: 'start'
					}}>
					<span>{t('isAgreed')}</span>

					<input
						id="checkb0x"
						type="checkbox"
						value={service?.isAgreed || ''}
						onChange={(e) => {
							setService((old) => ({
								...old,
								isAgreed: e.target.checked
							}))
						}}
					/>
				</label>
				<button
					className="btnSubmit"
					submit=""
					onClick={createServiceFc}>
					{t('Save')}
				</button>
			</main>
		</form>
	)
}
