import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
	debug: true,
	interpolation: {
		escapeValue: false
	},
	resources: {
		uz: {
			translation: {
				YourQueues: 'Sizning navbatlaringiz',
				Dear: 'Hurmatli',
				YouHaveNotQueue: "Sizda hali navbatlar yo'q",
				ProvideCodeOrQR:
					"Kod orqali navbat qo'shing yoki QR-ni skanerlang",
				EnterCode: 'Kodni kiriting',
				Home: 'Asosiy',
				Queue: 'Navbat',
				Monitoring: 'Monitoring',
				Profile: 'Profil',
				ToJoinQueue: 'Navbatga kirish uchun',
				UseCodeOrQR: 'kod yoki QR skanerdan foydalaning',
				Understand: 'Hammasi tushunarli!',
				Queues: 'Navbatlar',
				ListOfQueues: "Navbatlar ro'yxati",
				CreatedData: "Qo'shilgan sana",
				CurrentNumber: 'Joriy raqam №',
				Create: "Qo'shish",
				CreateQueue: "Navbat qo'shing",
				QueueName: 'Navbat nomi',
				Description: 'Tavsif',
				MonFri: 'Dush-Jum',
				MonSat: 'Dush-Shan',
				Everday: 'Har kuni',
				Custimize: 'Moslashtirish',
				Start: 'Boshlash',
				End: 'Oxiri',
				Next: 'Keyingisi',
				Services: 'Xizmatlar',
				NoServices: "Xizmatlar yo'q",
				AddService: "Xizmat qo'shish",
				СreateService: "Xizmatni qo'shish",
				Duration: 'Davomiyligi',
				Fee: "To'lov",
				min: 'min',
				sum: "so'm",
				isAgreed: 'Kelishilgan',
				Save: 'Saqlash',
				History: 'Tarix',
				Taken: 'Band qilingan',
				Created: "Qo'shilgan",
				AverageInterval: "O'rtacha interval",
				Minute: 'minut',
				Your: 'Sizning',
				InLineWith: 'Navbatga turilgan vaqt',
				Code: 'Kod',
				NoQueuesCreatedYet:
					"Sizda hozircha hech qanday navbat qo'shilmagan",
				AddNewQueueUseButton:
					"Tugma yordamida yangi navbat qo'shing",
				Username: 'Foydalanuvchi ismi',
				PhoneNumber: 'Telefon raqam',
				Tariff: 'Tarif',
				ChangeTariff: "Tarifni o'zgartirish",
				Exit: 'Chiqish',
				Share: 'Ulashish',
				NeverShareNumber:
					'Biz sizning raqamingizni hech kimga bermaymiz.',
				FirstName: 'Ismingiz',
				MiddleName: 'Otasingizni ismi',
				LastName: 'Familiyangiz',
				ConfirmPassword: 'Parolni tasdiqlang',
				NeverShareEmail:
					'Biz sizning emailingizni hech kimga bermaymiz.',
				Password: 'Parol',
				Language: 'Til',
				ChangeLanguage: "Tilni o'zgartirish",
				Back: 'Orqaga',
				Submit: 'Saqlash',
				MonitoringQueues: 'Navbatlar manitoringi',
				UserInfo: 'F.I.Sh',
				NoData: "Ma'lumotlar yo'q",
				WorkingHours: 'Ish soatlari',
				Note: 'Eslatma',
				Current: 'Hozirgi',
				Free: "Qabulda mijoz yo'q",
				WorkDay: 'Ish kuni',
				NotWorkDay: 'Dam olish',
				YourStatus: 'Sizning vaziyatingiz',
				QueueCode: 'Navbat kodi',
				ServiceName: 'Servis nomi',
				DoYouReallyWantToBeInLine:
					"Haqiqatan ham navbatda bo'lishni xohlaysizmi ?",
				YouAreSuccessfullyJoinedToQueue:
					"Siz muvaffaqiyatli qo'shildingiz",
				Watch: 'Kuzatish',
				Join: "Qo'shilish",
				Close: 'Yopish',
				DoYouReallyWantToExit:
					'Chiqishni haqiqatan ham xohlaysizmi',
				IStay: 'Qolaman',
				Yes: 'Xa',
				ThisIsYourQueuesQRCode: 'Bu sizning QR kodingiz',
				ImHere: 'Shu yerdaman ',
				Skip: "O'tkazib yuborish",
				DoYouWantToSkip: "Navbatni o'tkazishni xohlaysizmi ?",
				GoBack: 'Orqaga',
				Cancel: 'Chiqish',
				Active: 'Qabulda',
				Calling: 'Sizning navbatingiz',
				Pending: 'Kutilmoqda',
				Done: 'Tugatildi',
				Missed: "O'tkazish yuborilgan",
				Cancelled: 'Bekor qilingan',
				Skipping: 'Kechiktirilyapti',
				Unknown: 'No malum',
				YourTurnNowAreYouHere:
					'Sizning navbatingiz, siz shu yerdamisiz ?',
				DoYouWantToCancelYourQueue:
					'Navbatingizni bekor qilasizmi ?',
				QueueNotAcceptNumbersLessThanEqualZero:
					'Navbat noldan kichik yoki nolga teng raqamni qabul qilmaydi',
				QueueSchemeNotFound: 'Navbat topilmadi',
				QueueSchemeInvalidUnique: 'Navbat kodi yaroqsiz',
				TEXTNOTCORRECT: 'MATIN XATO',
				YouAreSuccessfullyJoinedTo:
					"Siz muvaffaqiyatli qo'shildingiz, sizning navbatingiz",
				RedirectingToQueueWithCode:
					"Qayta yo'naltirilmoqda, kod bilan",
				YouExitedQueue: 'Siz navbatdan chiqdingiz',
				Reviews: 'ta sharh',
				NotRatedYet: 'baholanmagan',
				ChangeLanguageDesc: "Tilni o'zgartirish sahifasi",
				About: 'Biz haqimizda',
				AboutDesc: 'Bu bizning dasturimiz haqida',
				СreateServiceDesc: 'Xizmat yarating sahifasi',
				MonitoringDesc: 'Navbatlar monitoring sahifasi',
				YourQueuesDesc: 'Sizning navbatlar sahifasi',
				Observe: 'Kuzatish',
				ObserveDesc: 'Kuzatish sahifasi',
				ProfileDesc: 'Shaxsiy sahifa',
				CreateQueueDesc: 'Navbat yaratish sahifasi',
				QueuesDesc: 'Navbatlar sahifasi',
				ServiceNamesDesc: 'Xizmat nomlari sahifasi',
				ServicesDesc: 'Xizmatlar sahifasi',
				YourQueueHasBeenDone: 'Sizning navbatingiz tugadi',
				PleaseRateService: 'Iltimos, xizmatni baholang',
				Sun: 'Yak',
				Mon: 'Dush',
				Tue: 'Sesh',
				Wed: 'Chor',
				Thu: 'Pay',
				Fri: 'Jum',
				Sat: 'Shan',
				Room: 'Xona',
				YouHaveToGoRoom:
					"Ko'rsatilgan xonaga boring, xona raqami",
				Yours: 'Sizning navbatingiz',
				Favorites: 'Sevimlilar',
				active: 'Qabulda',
				pending: 'Kutilmoqda',
				done: 'Tugatildi',
				missed: "O'tkazibdi yuborildi",
				cancelled: 'Bekor qilindi',
				skipping: 'Kechiktirilyapti',
				calling: 'Chaqirilmoqda',
				MissYourTurnAutomaticallyClose:
					"Agar siz navbatni 5 marta o'tkazsangiz, avtomatik ravishda yopiladi",
				TodayNotWork: 'Bugun ish kuni emas',
				YouHaveNotFavoriteListYet:
					"Hali sevimli ro'yxatingiz yo'q",
				PeopleInQueue: 'Navbatdagi odamlar',
				Workingdays: 'Ish kunlari',
				Adress: 'Manzil',
				LeaveYourComments: "O'z izohlaringizni qoldiring...",
				NumberChangingBlockedOpensIn:
					"O'zgartirish bloklangan, ochiladi:",
				AddToWishlist: "Sevimlilarga qo'shish",
				RemoveFromWishlist: 'Sevimlilardan olib tashlash'
			}
		},
		ru: {
			translation: {
				YourQueues: 'Ваши очереди',
				Dear: 'Уважаемый(ая)',
				YouHaveNotQueue: 'У вас пока нет записей в очереди',
				ProvideCodeOrQR:
					'Добавьте очередь через код или отсканируйте QR',
				EnterCode: 'Ввести код',
				Home: 'Главная',
				Queue: 'Очередь',
				Monitoring: 'Мониторинг',
				Profile: 'Профиль',
				ToJoinQueue: 'Чтобы занять очередь',
				UseCodeOrQR: 'используйте код или QR сканер',
				Understand: 'Все понятно!',
				Queues: 'Oчереди',
				ListOfQueues: 'Список очередей',
				CreatedData: 'Дата создания',
				CurrentNumber: 'Текущий №',
				Create: 'Создать',
				CreateQueue: 'Создать очередь',
				QueueName: 'Название очереди',
				Description: 'Описание',
				MonFri: 'Пн-Пт',
				MonSat: 'Пн-Сб',
				Everday: 'Каждый день',
				Custimize: 'Настроить',
				Start: 'Начать',
				End: 'Kонец',
				Next: 'Следующий',
				Services: 'Услуги',
				NoServices: 'Hет услуг',
				AddService: 'Добавить услугу',
				СreateService: 'Создать сервис',
				Duration: 'Продолжительность',
				Fee: 'Платеж',
				min: 'мин',
				sum: 'сум',
				isAgreed: 'Согласен',
				Save: 'Сохранить',
				History: 'История',
				Taken: 'Занято',
				Created: 'Созданный',
				AverageInterval: 'Средний интервал',
				Minute: 'минут',
				Your: 'Ваш',
				InLineWith: 'Вы в очереди с',
				Code: 'Код',
				NoQueuesCreatedYet:
					'У вас еще нет созданных очередей',
				AddNewQueueUseButton:
					'Добавте новую очередь с помощью кнопки',
				Username: 'Имя пользователя',
				PhoneNumber: 'Номер телефона',
				Tariff: 'Тариф',
				ChangeTariff: 'Cменить тариф',
				Exit: 'Выйти',
				Share: 'Поделиться',
				NeverShareNumber:
					'Мы гарантируем конфиденциальность вашего номера телефона',
				FirstName: 'Имя',
				MiddleName: 'Отчество',
				LastName: 'Фамилия',
				ConfirmPassword: 'Подтвердите пароль',
				NeverShareEmail:
					'Мы гарантируем конфиденциальность вашей электронной почты',
				Password: 'Пароль',
				Language: 'Язык',
				ChangeLanguage: 'Изменить язык',
				Back: 'Назад',
				Submit: 'Подтвердить',
				MonitoringQueues: 'Мониторинг очередей',
				UserInfo: 'Ф. И. О.',
				NoData: 'Нет данных',
				WorkingHours: 'Часы работы',
				Note: 'Заметки',
				Current: 'Tекущий',
				Free: 'Клиентов пока нет',
				WorkDay: 'Работа',
				NotWorkDay: 'Не рабочий день',
				YourStatus: 'Ваш статус',
				QueueCode: 'Код очереди',
				ServiceName: 'Имя сервиса',
				DoYouReallyWantToBeInLine:
					'Вы действительно хотите быть в очереди?',
				YouAreSuccessfullyJoinedToQueue:
					'Вы успешно присоединились к очереди',
				Watch: 'Смотреть',
				Join: 'Присоединиться',
				Close: 'Закрыть',
				DoYouReallyWantToExit:
					'Вы действительно хотите выйти',
				IStay: 'Я останусь',
				Yes: 'Да',
				ThisIsYourQueuesQRCode: 'Это QR-код вашей очереди',
				ImHere: 'Я здесь',
				Skip: 'Пропустить',
				DoYouWantToSkip: 'Хотите пропустить очередь ?',
				GoBack: 'Назад',
				Cancel: 'Oтменить',
				Active: 'На приеме',
				Calling: 'Ваша очередь',
				Pending: 'В ожидании',
				Done: 'Завершено',
				Missed: 'Пропущенно',
				Cancelled: 'Отменено',
				Skipping: 'Пропуск',
				Unknown: 'Неизвестно',
				DoYouWantToCancelYourQueue:
					'Хотите ли вы отменить свою очередь ?',
				YourTurnNowAreYouHere:
					'Сейчас ваша очередь. Вы здесь?',
				QueueNotAcceptNumbersLessThanEqualZero:
					'Очередь не принимает номера меньше нуля или равные нулю',
				QueueSchemeNotFound: 'Схема очередей не найдена',
				QueueSchemeInvalidUnique:
					'Код очереди недействителен',
				TEXTNOTCORRECT: 'ТЕКСТ НЕВЕРНЫЙ',
				YouAreSuccessfullyJoinedTo:
					'Вы успешно присоединились, ваша очередь',
				RedirectingToQueueWithCode:
					'Перенаправление в очередь с кодом',
				YouExitedQueue: 'Вы вышли из очереди',
				Reviews: 'отзывы',
				NotRatedYet: 'нет отзывов',
				ChangeLanguageDesc: 'Страница изменения языка',
				About: 'О нас',
				AboutDesc: 'О нашем приложении',
				СreateServiceDesc: 'Страница создания услуги',
				MonitoringDesc: 'Страница мониторинга очередей',
				YourQueuesDesc: 'Страница ваших очередей',
				Observe: 'Наблюдать',
				ObserveDesc: 'Страница наблюдения',
				ProfileDesc: 'Профиль страница',
				CreateQueueDesc: 'Страница создания очереди',
				QueuesDesc: 'Страница очередей',
				ServiceNamesDesc: 'Страница названий услуг',
				ServicesDesc: 'Страница услуг',
				YourQueueHasBeenDone: 'Ваша очередь завершена',
				PleaseRateService: 'Пожалуйста, оцените сервис',
				Sun: 'Вс',
				Mon: 'Пн',
				Tue: 'Вт',
				Wed: 'Ср',
				Thu: 'Чт',
				Fri: 'Пт',
				Sat: 'Сб',
				Room: 'Комната',
				YouHaveToGoRoom: 'Вам нужно пойти в комнату',
				Yours: 'Ваш',
				Favorites: 'Избранное',
				active: 'Активно',
				pending: 'В ожидании',
				done: 'Завершено',
				missed: 'Пропущенно',
				cancelled: 'Отменено',
				skipping: 'Пропускается',
				calling: 'Вызывается',
				MissYourTurnAutomaticallyClose:
					'Если вы пропустите 5 раз свою очередь, она автоматически закроется',
				TodayNotWork: 'Сегодня не рабочий день',
				YouHaveNotFavoriteListYet:
					'У вас пока нет списка избранных',
				PeopleInQueue: 'Люди в очереди',
				Workingdays: 'Рабочие дни',
				Adress: 'Адрес',
				LeaveYourComments: 'Оставьте свои комментарии...',
				NumberChangingBlockedOpensIn:
					'Изменение заблокировано, открывается в',
				AddToWishlist: 'Добавить в избранное',
				RemoveFromWishlist: 'Удалить из избранного'
			}
		},
		en: {
			translation: {
				YourQueues: 'your Queues',
				Dear: 'Dear',
				YouHaveNotQueue: 'You have not queues yet',
				ProvideCodeOrQR:
					'Add a queue via code or scan the QR',
				EnterCode: 'Enter the code',
				Home: 'Home',
				Queue: 'Queue',
				Monitoring: 'Monitoring',
				Profile: 'Profile',
				ToJoinQueue: 'To join a queue',
				UseCodeOrQR: 'use code or QR scanner',
				Understand: 'I understand!',
				Queues: 'Queues',
				ListOfQueues: 'List of queues',
				CreatedData: 'Date of creation',
				CurrentNumber: 'Current №',
				Create: 'Create',
				CreateQueue: 'Create queue',
				QueueName: 'Queue name',
				Description: 'Description',
				MonFri: 'Mon-Fri',
				MonSat: 'Mon-Sat',
				Everday: 'Everyday',
				Custimize: 'Customise',
				Start: 'Start',
				End: 'End',
				Next: 'Next',
				Sevices: 'Sevices',
				NoServices: 'No Services',
				AddService: 'Add service',
				СreateService: 'Create service',
				Duration: 'Duration',
				Fee: 'Fee',
				min: 'min',
				sum: 'sum',
				isAgreed: 'is agreed',
				Save: 'Save',
				History: 'History',
				Taken: 'Taken',
				Created: 'Created',
				AverageInterval: 'Average interval',
				Minute: 'minute',
				Your: 'Your',
				InLineWith: 'You are in line since',
				Code: 'Code',
				NoQueuesCreatedYet:
					'You have not created any queues yet',
				AddNewQueueUseButton:
					'Add new queue using the button',

				Username: 'Username',
				PhoneNumber: 'Phone number',
				Tariff: 'Tariff',
				ChangeTariff: 'Change tariff',
				Exit: 'Exit',
				Share: 'Share',
				NeverShareNumber:
					"We'll never share your number with anyone else.",
				FirstName: 'First Name',
				MiddleName: 'Middle Name',
				LastName: 'Last Name',
				ConfirmPassword: 'Confirm Password',
				NeverShareEmail:
					"We'll never share your email with anyone else.",
				Password: 'Password',
				Language: 'Language',
				ChangeLanguage: 'Change language',
				Back: 'Back',
				Submit: 'Submit',
				MonitoringQueues: 'Monitoring queues',
				UserInfo: 'Full name',
				NoData: 'No data',
				WorkingHours: 'Working hours',
				Note: 'Note',
				Current: 'Current',
				Free: 'No customers yet',
				WorkDay: 'Work day',
				NotWorkDay: "Don't work",
				YourStatus: 'Your Status',
				QueueCode: 'Queue code',
				ServiceName: 'Service name',
				DoYouReallyWantToBeInLine:
					'Do you really want to be in line?',
				YouAreSuccessfullyJoinedToQueue:
					'You are successfully joined to the Queue!',
				Watch: 'Watch',
				Join: 'Join',
				Close: 'Close',
				DoYouReallyWantToExit: 'Do you really want to exit',
				IStay: 'I stay',
				Yes: 'Yes',
				ThisIsYourQueuesQRCode:
					"This is your queue's QR code",
				ImHere: 'I am here',
				Skip: 'Skip',
				DoYouWantToSkip: 'Do you want to skip ?',
				GoBack: 'Go back',
				Cancel: 'Cancel',
				Active: 'Active',
				Calling: 'Calling',
				Pending: 'Pending',
				Done: 'Done',
				Missed: 'Missed',
				Cancelled: 'Cancelled',
				Skipping: 'Skipping',
				Unknown: 'Unknown',
				DoYouWantToCancelYourQueue:
					'Do you want to cancel your queue ?',
				YourTurnNowAreYouHere:
					'Your turn now! Are you here ?',
				QueueNotAcceptNumbersLessThanEqualZero:
					'The queue does not accept numbers less than zero or equal to zero',
				QueueSchemeNotFound: 'Queues Scheme is not found',
				QueueSchemeInvalidUnique:
					"Queue Scheme's unique is invalid",
				TEXTNOTCORRECT: 'TEXT IS NOT CORRECT',
				YouAreSuccessfullyJoinedTo:
					'You are successfully joined, your queue is',
				RedirectingToQueueWithCode:
					'Redirect the code to the Queue',
				YouExitedQueue: 'You exited the queue',
				Reviews: 'reviews',
				NotRatedYet: 'not rated yet',
				ChangeLanguageDesc: 'Change language page',
				About: 'About',
				AboutDesc: 'This is about our app',
				СreateServiceDesc: 'Create service page',
				MonitoringDesc: 'Monitoring queues page',
				YourQueuesDesc: 'Your queues page',
				Observe: 'Observe',
				ObserveDesc: 'Observe page',
				ProfileDesc: 'Profile page',
				CreateQueueDesc: 'Create queue page',
				QueuesDesc: 'Queues page',
				ServiceNamesDesc: 'Service names page',
				ServicesDesc: 'Services page',
				YourQueueHasBeenDone: 'Your queue has been done',
				PleaseRateService: 'Please rate the service',
				Sun: 'Sun',
				Mon: 'Mon',
				Tue: 'Tue',
				Wed: 'Wed',
				Thu: 'Thu',
				Fri: 'Fri',
				Sat: 'Sat',
				Room: 'Room',
				YouHaveToGoRoom: 'You have to go to room',
				Yours: 'Yours',
				Favorites: 'Favorites',
				active: 'Active',
				pending: 'Pending',
				done: 'Done',
				missed: 'Missed',
				cancelled: 'Cancelled',
				skipping: 'Skipping',
				calling: 'Calling',
				MissYourTurnAutomaticallyClose:
					'If you miss your turn 5 times, it will automatically close',
				TodayNotWork: 'Today is not working day',
				YouHaveNotFavoriteListYet:
					"You don't have a favorite list yet",
				PeopleInQueue: 'People in queue',
				Workingdays: 'Working days',
				Adress: 'Adress',
				LeaveYourComments: 'Leave your comments...',
				NumberChangingBlockedOpensIn:
					'Changing is blocked, opens in',
				AddToWishlist: 'Add to wishlist',
				RemoveFromWishlist: 'Remove from wishlist'
			}
		}
	}
})

export default i18n
