import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
	CREATE_SERVICE_ROUTE,
	EDIT_QUEUE_SCHEME_ROUTE,
	QUEUE_SCHEME_ROUTE,
	QUEUE_START_ROUTE
} from '../util/routenames'
import { serviceStore } from '../store/serviceStore'
import { useTranslation } from 'react-i18next'

export const ServiceList = () => {
	const navigate = useNavigate()
	const { id } = useParams()
	const { service, getServicesByQueueSchemeId } = serviceStore()
	const { t } = useTranslation()

	const submit = async () => {
		navigate(QUEUE_SCHEME_ROUTE + '/' + id + CREATE_SERVICE_ROUTE)
	}
	const nextFc = async () => {
		navigate(QUEUE_SCHEME_ROUTE + '/' + id + QUEUE_START_ROUTE)
	}

	useEffect(() => {
		const fetchData = async () => {
			await getServicesByQueueSchemeId(id)
		}
		fetchData()
	}, [getServicesByQueueSchemeId, id])

	return (
		<>
			<header className="QCHeader">
				<i
					className="la la-angle-left la-2x"
					onClick={() =>
						navigate(EDIT_QUEUE_SCHEME_ROUTE + '/' + id)
					}></i>
				<h2 className="mainTopTitle flex-grow-1">
					{t('Services')}
				</h2>
				<button onClick={nextFc}>{t('Next')}</button>
			</header>
			<main className="QCMain">
				{service.length ? (
					service.map((s) => <p key={s.id}>{s.name}</p>)
				) : (
					<h2
						style={{
							display: 'flex',
							justifyContent: 'center',
							marginTop: 'auto'
						}}>
						{t('NoServices')}
					</h2>
				)}

				<button
					className="btnSubmit"
					submit=""
					onClick={submit}>
					{t('AddService')}
				</button>
			</main>
		</>
	)
}
