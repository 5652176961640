import { $authHost } from '.'

export const getQueueSchemes = async () => {
	const { data } = await $authHost.get('queue-scheme/list')
	return data
}

export const getWorkdayAdvices = async (queueSchemeId) => {
	const { data } = await $authHost.get(
		'queue-scheme/workday-advices/' + queueSchemeId
	)
	return data
}

export const activate = async (queueSchemeId, startDate) => {
	const { data } = await $authHost.post(
		'queue-scheme/activate/' + queueSchemeId,
		{ startDate }
	)
	return data
}

export const createQueueScheme = async (queueScheme) => {
	const { data } = await $authHost.post('queue-scheme', queueScheme)
	return data
}

export const checkQueueScheme = async (unique) => {
	const { data } = await $authHost.get(
		'queue-scheme/check/' + unique
	)
	return data
}

export const getOneQueueSchemeFromClient = async (queueSchemeId) => {
	const { data } = await $authHost.get(
		'queue-scheme/client/' + queueSchemeId
	)
	return data
}


