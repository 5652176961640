import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { OBSERVE_SERVICES_ROUTE } from '../util/routenames'
import './css/Observing.css'
import { queueSchemeStore } from '../store/queueSchemeStore'
import { toDataURL } from 'qrcode'
import { authStore } from '../store/authStore'
import { useTranslation } from 'react-i18next'
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter
} from 'react-bootstrap'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'
import { recepientStore } from '../store/recepientStore'
import { Helmet } from 'react-helmet'
import { Rate } from 'antd'
import { wishlistStore } from '../store/wishlistStore'

export const ObserveQueue = () => {
	const { id } = useParams()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [queue, setQueue] = useState()
	const [qrImg, setQrImg] = useState()
	const [handleModal, setHandleModal] = useState(false)
	const [step, setStep] = useState(1)
	const [prevNum, setPrevNum] = useState()
	const [page, setPage] = useState(1)
	const [hasMore, setHasMore] = useState(true)

	const {
		getOneQueueSchemeFromClient,
		skipRecepient: qSkipRecepient,
		queueScheme,
		returnRecepient: qReturnRecepient,
		getReviewByQueueSchemeId,
		reviews
	} = queueSchemeStore()
	const { skipRecepient, returnRecepient } = recepientStore()
	const { user } = authStore()
	const { isInWishlist, addToWishlist } = wishlistStore()

	useEffect(() => {
		const fetchData = async () => {
			setQueue(
				queueScheme.find((q) => q.id === id) ||
					(await getOneQueueSchemeFromClient(id))
			)
		}
		fetchData()
	}, [getOneQueueSchemeFromClient, id, queueScheme])

	const observer = useRef(null)

	const lastElementRef = useRef(null)

	useEffect(() => {
		const fetchMoreData = async () => {
			const reviews = await getReviewByQueueSchemeId(id, page)
			if (
				reviews.filter((r) => r.queueSchemeId === id).length <
				10
			) {
				setHasMore(false)
			}
		}
		fetchMoreData()
	}, [page, getReviewByQueueSchemeId, id])

	useEffect(() => {
		if (
			!reviews.filter((r) => r.queueSchemeId === id).length ||
			reviews.filter((r) => r.queueSchemeId === id).length <
				10 ||
			lastElementRef.current === null
		)
			return
		if (observer.current) observer.current.disconnect()

		observer.current = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting && hasMore) {
					setPage((prevPage) => prevPage + 1)
				}
			},
			{
				root: null,
				rootMargin: '100px',
				threshold: 0.5
			}
		)

		if (lastElementRef.current) {
			observer.current.observe(lastElementRef.current)
		}
	}, [hasMore, reviews, id, lastElementRef.current]) // eslint-disable-line
	// FIX THIS
	useEffect(() => {
		if (!queue) return
		const url =
			process.env.REACT_APP_SITE_URL + 'r/' + queue.unique
		toDataURL(url, {
			width: 150,
			margin: 0,
			color: { light: '#00000000' }
		})
			.then((data) => {
				const img = React.createElement('img', {
					src: data,
					alt:
						process.env.REACT_APP_SITE_URL +
						'r/' +
						queue.unique +
						'?ref=' +
						user.nvbtId,
					className: 'qrCode'
				})
				setQrImg(img)
			})
			.catch((e) => console.error(e))
	}, [id, queue, user.nvbtId])

	const currentWorkday = queue
		? queue.workdays.find(
				(wd) => wd.workday === new Date().getDay()
			)
		: null

	const weekdays = [
		t('Sun'),
		t('Mon'),
		t('Tue'),
		t('Wed'),
		t('Thu'),
		t('Fri'),
		t('Sat')
	]

	const closeModalFc = () => {
		setHandleModal(false)
		setStep(0)
	}

	const buttonSkip = () => {
		setHandleModal(true)
		setStep(1)
		setPrevNum(queue.queues[0].recepients[0]?.number)
	}

	const buttonReturn = () => {
		setHandleModal(true)
		setStep(3)
	}

	const skipRecepientFc = () => {
		skipRecepient(
			queue.id,
			queue.queues[0].id,
			queue.queues[0].recepients[0].id,
			queue.queues[0].recepients[0].number
		).then(([number, status]) => {
			qSkipRecepient(
				queue.id,
				queue.queues[0].recepients[0].id,
				number,
				status
			)
		})
		setStep(step + 1)
	}

	const returnRecepientFc = async () => {
		await returnRecepient(
			queue.id,
			queue.queues[0].id,
			queue.queues[0].recepients[0].id
		)
		qReturnRecepient(queue.id, queue.queues[0].recepients[0].id)
		setStep(step + 1)
	}

	if (!queue) return <></>

	return (
		<>
			<Helmet>
				<title>{t('Observe')} – Queue Expert</title>
				<meta name="description" content={t('ObserveDesc')} />
			</Helmet>
			<header className="QCHeader">
				<i
					className="la la-angle-left la-2x"
					onClick={() => navigate(-1)}></i>
				<h2 className="mainTopTitle">{queue.name}</h2>
			</header>
			<main className="result">
				<div
					style={{
						display: 'flex',
						gap: '10px',
						justifyContent: 'space-between',
						alignItems: 'center',
						color: 'grey'
					}}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '10px'
						}}>
						<h2>{queue.name}</h2>
						<span
							style={{
								display: 'flex',
								alignItems: 'center',
								fontSize: '13px',
								color: 'gray'
							}}>
							<i
								className="las la-star"
								style={{
									color: 'gold',
									marginRight: '3px'
								}}></i>
							{Math.floor(queue.rating * 10) / 10}
						</span>

						<span
							style={{
								display: 'flex',
								alignItems: 'center',
								fontSize: '13px',
								color: 'gray'
							}}>
							({queue.ratesCount} {t('Reviews')})
						</span>
					</div>
					<i
						onClick={() =>
							addToWishlist(
								queue.id,
								queue.name,
								queue.unique
							)
						}
						className="las la-thumbtack la-2x"
						style={{
							color: isInWishlist(queue.id)
								? 'red'
								: 'grey',
							cursor: 'pointer'
						}}></i>
				</div>
				<label className="observeLabel">
					<img alt="/" hidden className="observeImage" />
				</label>
				<p className="cGreen pl-0 pt-3">
					{t('AverageInterval')}:{' '}
					{queue.activeWorkTime
						? (
								queue.activeWorkTime.reduce(
									(a, b, _ar, arr) =>
										(a + b) / arr.length
								) / 1000
							).toFixed(2) + ' мин.(сек)'
						: t('NoData')}
				</p>

				<div className="observeBody">
					<i className="las la-calendar-week la-2x icon"></i>
					<div className="observeNoteWorkingHours">
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '6px'
							}}>
							<div className="observeTitle">
								{t('Workingdays')}
							</div>
							<div
								style={{
									display: 'flex',
									gap: '8px'
								}}>
								{weekdays.map((wd, i) => {
									const wdd = queue.workdays.find(
										(a) => a.workday === i
									)
									return (
										<div
											key={i}
											className={
												'workdays ' +
												(new Date().getDay() ===
												i
													? 'today'
													: wdd
														? 'green'
														: 'red')
											}>
											<p className="workdaysNames">
												{wd}
											</p>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</div>
				{queue.queues.length ? (
					<div className="observeBody">
						<i className="las la-user la-2x icon"></i>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between'
							}}
							className="observeNoteWorkingHours">
							<div className="observeQueueCount">
								<div className="observeTitle">
									{t('PeopleInQueue')}
								</div>

								<div
									className="dark text-center"
									key={
										queue.queues[0]
											?.recepientsCount
									}>
									{queue.queues[0]?.recepientsCount}{' '}
								</div>
							</div>
							<figcaption
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									width: '110px'
								}}>
								<div className="observeTitle">
									{t('Current')} №
								</div>
								<div className="dark  text-center">
									{!queue.queues[0].active
										? t('Free')
										: queue.queues[0].active ===
											  queue.queues[0]
													?.recepients[0]
													?.number
											? t('Yours')
											: queue.queues[0].active}
								</div>
							</figcaption>
						</div>
					</div>
				) : (
					''
				)}
				<div className="observeBody border-0">
					<i className="la la-clock la-2x icon"></i>
					<div className="observeNoteWorkingHours">
						<div
							style={{
								height: '50px',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								gap: '6px'
							}}>
							<div className="observeTitle">
								{t('WorkingHours')}
							</div>
							{currentWorkday ? (
								<div className="dark">
									{currentWorkday.startTime} -{' '}
									{currentWorkday.finishTime}
								</div>
							) : (
								t('DontWorkToday')
							)}
						</div>
						{/* {queue.startTime && queue.finishTime ? (
							<div>
								Обед &nbsp;
								<span className="dark">
									{moment(queue.startTime).format(
										'HH:mm'
									)}{' '}
									-{' '}
									{moment(queue.finishTime).format(
										'HH:mm'
									)}
								</span>
							</div>
						) : (
							<p></p>
						)} */}
					</div>
				</div>
				<div className="observeBody">
					<i className="la la-info la-2x icon"></i>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between'
						}}
						className="observeNoteWorkingHours">
						<div
							style={{
								height: '50px',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								gap: '6px'
							}}>
							<div className="observeTitle">
								{t('Note')}
							</div>
							<div className="dark">
								{queue.description}
							</div>
						</div>
						{queue.room ? (
							<b
								className="queueNum"
								style={{
									marginLeft: '5px'
								}}>
								{t('Room')} {queue.room}
							</b>
						) : (
							<></>
						)}
					</div>
				</div>
				<div className="observeBody">
					<i class="las la-map-marker la-2x icon"></i>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between'
						}}
						className="observeNoteWorkingHours">
						<div
							style={{
								height: '50px',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								gap: '6px'
							}}>
							<div className="observeTitle">
								{t('Adress')}
							</div>
							<div className="dark">
								Ул. Шарофа Рашидова, дом 5Б
							</div>
						</div>
					</div>
				</div>
				<Link
					to={OBSERVE_SERVICES_ROUTE + '/' + id}
					className="align-items-center cabinetRow">
					<i className="la la-ambulance la-2x icon"></i>
					<div className="pl-3 flex-grow-1 dark">
						<div>{t('Services')}</div>
					</div>
					<i className="la la-1x la-angle-right"></i>
				</Link>

				{queue.queues.length ? (
					<>
						<div className="observeNumStatus">
							{queue.queues[0].recepients[0]?.number ? (
								<figcaption>
									<div
										style={{
											textAlign: 'center'
										}}>
										{t('Your')} №
									</div>
									<div className="dark font-size-5 text-center ">
										{
											queue.queues[0]
												.recepients[0]?.number
										}
									</div>
								</figcaption>
							) : (
								''
							)}
							{queue.queues[0].recepients[0]?.status ? (
								<figcaption>
									<div
										style={{
											textAlign: 'center'
										}}>
										{t('YourStatus')}
									</div>
									<div
										style={{
											textAlign: 'center',
											color: '#515142',
											fontSize: '30px'
										}}>
										{
											queue.queues[0]
												.recepients[0]?.status
										}
									</div>
								</figcaption>
							) : (
								''
							)}
						</div>
						<div className="cabinetRow mx-auto border-0">
							<figcaption className="qrCodeDiv">
								<div className="qrCodeImg">
									{qrImg}
								</div>
								<center
									style={{
										paddingTop: '10px'
									}}>
									<h2>{t('QueueCode')}</h2>
								</center>
								<div className="qrCodeNumber">
									{queue.unique}
								</div>
							</figcaption>{' '}
							{/** FIX HERE THE CSS */}
						</div>
						{queue.queues[0].recepients[0]?.status ===
						'calling' ? (
							<Button onClick={() => buttonSkip()}>
								{t('skip')}
							</Button>
						) : queue.queues[0].recepients[0]?.status ===
						  'skipping' ? (
							<Button onClick={() => buttonReturn()}>
								{t('return')}
							</Button>
						) : (
							''
						)}
						{reviews.filter((r) => r.queueSchemeId === id)
							.length ? (
							<section className="ratingMainBody">
								{reviews
									.filter(
										(r) => r.queueSchemeId === id
									)
									.map((review, index) => (
										<div
											className="serviceOneRating"
											key={index}
											ref={
												reviews.filter(
													(r) =>
														r.queueSchemeId ===
														id
												).length ===
												index + 1
													? lastElementRef
													: null
											}>
											<div className="serviceOneRatingBody">
												<div className="serviceOneRatingHeader">
													<div>
														<p className="serviceOneRatingText">
															{
																review
																	.reviewer
																	?.firstName
															}
														</p>
														<span className="serviceOneRatingDate">
															{new Date(
																review.createdAt
															).toLocaleDateString()}
														</span>
													</div>
													<Rate
														disabled
														value={
															review.rating
														}
														className="serviceOneRatingStars"
													/>
												</div>
												<p className="serviceOneRatingComment">
													{review.comment}
												</p>
											</div>
										</div>
									))}
							</section>
						) : (
							<div>
								<p>{t('NoReviews')}</p>
							</div>
						)}
					</>
				) : (
					<div className="notWork">
						<p className="notWorkText">
							{t('TodayNotWork')}
						</p>
					</div>
				)}

				{/* <nav className="d-flex align-items-center">
					<button
						className="queueBtn bordered mr-3"
						// onClick={AddQueue}
					>
						Наблюдать
					</button>
					<QueueJoinModal
						className="queueBtn"
						submit=""
						// onClick={SetActive}
					/>
				</nav> */}
				<div className="buttonModal">
					{/* {queue.queues[0].recepients[0].status ===
					'calling' ? ( */}

					<Modal
						centered
						show={handleModal}
						onHide={closeModalFc}>
						<ModalHeader></ModalHeader>
						<ModalBody>
							{step === 1 &&
								'Do you really want to skip your queue ?'}
							{step === 2 &&
								`Your queue changed from ${prevNum} to ${
									queue.queues[0].recepients[0]
										?.number
								}`}
							{step === 3 && ``}
						</ModalBody>
						<ModalFooter>
							{step === 1 ? (
								<>
									<Button
										onClick={() =>
											closeModalFc()
										}>
										{t('close')}
									</Button>
									<Button
										onClick={() =>
											skipRecepientFc()
										}>
										{t('Okay')}
									</Button>
								</>
							) : step === 3 ? (
								<>
									<Button
										onClick={() =>
											closeModalFc()
										}>
										{t('close')}
									</Button>
									<Button
										onClick={() =>
											returnRecepientFc()
										}>
										{t('Okay')}
									</Button>
								</>
							) : (
								<Button
									onClick={() => closeModalFc()}>
									{t('close')}
								</Button>
							)}
						</ModalFooter>
					</Modal>
					{/* ) : (
						''
					)} */}
				</div>
			</main>
		</>
	)
}
