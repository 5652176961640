import { $authHost } from '.'

export const createService = async (body) => {
	const { data } = await $authHost.post('service', body)
	return data
}

export const getServicesByQueueSchemeId = async (queueSchemeId) => {
	const { data } = await $authHost.get(
		'service/qs/' + queueSchemeId
	)
	return data
}
